.ham-icon-container {
  display: block;
  height: 30px;
  overflow: hidden;
}

.ham-icon-container img {
  height: 30px;
  width: 30px;
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.ham-icon-container img:hover, .ham-icon-container img:focus {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.ham-icon-container img ~ img {
  margin-top: 1rem;
}

@media (min-width: 900px) {
  .ham-icon-container {
    display: none;
  }
}

.ham-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  display: block;
}

.ham-menu svg {
  width: 3rem;
  height: 3rem;
}

.ham-menu.open {
  margin-top: calc((100% + 1rem) * -1);
}
