@import url("https://fonts.googleapis.com/css2?family=Andika+New+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 750px) {
  html {
    font-size: 95%;
  }
}

@media (max-width: 650px) {
  html {
    font-size: 85%;
  }
}

@media (max-width: 550px) {
  html {
    font-size: 75%;
  }
}

[data-custom-smooth-scroll] {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

section,
article {
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  scroll-snap-align: center;
}

.showHeader {
  -webkit-transform: unset;
          transform: unset;
  background: -webkit-gradient(linear, left top, left bottom, from(#000000a6), to(transparent));
  background: linear-gradient(-180deg, #000000a6, transparent);
  pointer-events: all;
}

.reveal-header {
  -webkit-transform: unset;
          transform: unset;
  pointer-events: all;
  background: -webkit-gradient(linear, left top, left bottom, from(#00000045), to(transparent));
  background: linear-gradient(-180deg, #00000045, transparent);
}

.header-with-bg {
  background-color: #000 !important;
}

@media (max-width: 650px) {
  .header-with-bg {
    background-color: revert;
  }
}

.primary-font {
  text-align: inherit;
  color: inherit;
  font-weight: 800;
  font-size: clamp(1.8rem, 5vw, 3rem);
  margin-bottom: 0.4em;
}

.secondary-font {
  text-align: inherit;
  color: inherit;
  font-weight: 600;
  font-size: clamp(1.6rem, 5vw, 2rem);
  margin-bottom: 0.3em;
}

.tertiary-font {
  text-align: inherit;
  color: inherit;
  font-weight: 600;
  font-size: clamp(1.4rem, 5vw, 1.4rem);
  margin-bottom: 0.2em;
}

.btn {
  display: inline-block;
  cursor: pointer;
  padding: 0.5em 2.5em;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  border-radius: 10px;
  outline: none;
  border: transparent 1px solid;
  color: #fff;
  background: #de2e2e;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 400;
  font-family: inherit;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
  z-index: 1;
}

.btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 0%;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  z-index: -1;
  background: transparent;
  border-radius: inherit;
}

.btn:hover, .btn:focus, .btn:active {
  color: #de2e2e;
  -webkit-transform: revert;
          transform: revert;
}

.btn:hover::before, .btn:focus::before, .btn:active::before {
  width: 100%;
  background: #fff;
}

@media (min-width: 650px) {
  .nav-link-home {
    display: none;
  }
}

.fixed-mobile-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

@media (min-width: 650px) {
  .fixed-mobile-logo {
    display: none;
  }
}

.progress-bar-contianer {
  pointer-events: none;
  height: 85vh;
  width: 3rem;
  position: fixed;
  left: 0rem;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 100;
}

.progress-bar-contianer #progress-bar {
  width: 1px;
  background: #de2e2e;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  will-change: auto;
  z-index: 10;
}

.progress-bar-contianer .progress-line-bg {
  width: 1px;
  height: 100%;
  background: #fff;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  will-change: auto;
  position: absolute;
}

.progress-bar-contianer .progress-logo {
  width: 3rem;
  height: 3rem;
  z-index: 100;
  position: fixed;
  will-change: auto;
  -webkit-transition: 0.3s cubic-bezier(0.08, 0.82, 0.17, 1);
  transition: 0.3s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
  cursor: not-allowed;
}

.custom-small-heading {
  margin: 4rem;
  letter-spacing: 0.4rem;
  display: inline-block;
  position: relative;
  font-weight: normal;
  margin-top: 7rem;
}

.custom-small-heading::after {
  position: absolute;
  content: "";
  width: 9rem;
  height: 1px;
  background: #fff;
  bottom: -0.5rem;
  right: -3rem;
}

.custom-hero-heading-container {
  display: block;
  margin: 0 auto;
}

.custom-hero-heading-container h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.4rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

.custom-hero-heading-container .line-hero {
  position: absolute;
  width: 110%;
  bottom: -25%;
  z-index: -1;
  top: 50%;
  left: 50%;
  background: #000;
  -webkit-transform: translate(-50%, 9%);
          transform: translate(-50%, 9%);
  height: 2rem;
}

.rich-quote-container-container {
  text-align: center;
}

.rich-quote-container-container blockquote {
  padding: 6rem 3rem;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 1.5rem;
  letter-spacing: 0.4rem;
  line-height: 1.2;
}

.rich-quote-container-container blockquote::after, .rich-quote-container-container blockquote::before {
  content: "";
  width: 10rem;
  background: #fff;
  border: 1px solid #fff;
  display: block;
  margin: 0 auto;
}

.rich-quote-container-container blockquote::after {
  margin-top: 1rem;
}

.rich-quote-container-container blockquote::before {
  margin-bottom: 1rem;
}

.hero-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20rem 1fr;
      grid-template-columns: 20rem 1fr;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5rem;
  position: relative;
}

.hero-grid-container .txt-container {
  position: absolute;
  top: 40%;
  left: 5rem;
  letter-spacing: 0.3rem;
  font-weight: 400;
}

.hero-grid-container .txt-container h1 {
  font-weight: 400;
  max-width: 60rem;
  font-size: 2.2rem;
}

.hero-grid-container .img-container {
  grid-column: 2/-1;
}

.hero-grid-container .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media (max-width: 650px) {
  .hero-grid-container {
    display: block;
    padding: 10rem 0;
    padding-top: 0;
    padding-bottom: 5rem;
    position: relative;
    height: auto;
  }
  .hero-grid-container .img-container {
    height: 40rem;
    width: 100%;
  }
  .hero-grid-container .txt-container {
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .hero-grid-container .txt-container h1 {
    max-width: initial;
    width: 100%;
    text-align: center;
  }
  .hero-grid-container .txt-container h1::after {
    content: "";
    display: block;
    width: 13rem;
    margin: 0 auto;
    border: 1px solid #fff;
    background-color: #fff;
    bottom: -1rem;
    position: relative;
  }
}

.hero-slider-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20rem 65vw;
      grid-template-columns: 20rem 65vw;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5rem;
}

.hero-slider-grid-container .txt-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  position: absolute;
  top: 40%;
  left: 5rem;
  letter-spacing: 0.3rem;
  font-weight: 400;
}

.hero-slider-grid-container .txt-container h1 {
  font-weight: 400;
  width: 75%;
  max-width: 80rem;
  font-size: 2.2rem;
}

.hero-slider-grid-container .hero-slider-container {
  grid-column: 2/-1;
  grid-row: 1/-1;
}

.hero-slider-grid-container .hero-slider-container .img-container {
  width: 100%;
  height: 100%;
}

.hero-slider-grid-container .hero-slider-container .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  display: block;
}

@media (max-width: 650px) {
  .hero-slider-grid-container {
    height: auto;
    padding: 5rem 0;
    -ms-grid-columns: 100vw;
        grid-template-columns: 100vw;
    -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    gap: 5rem;
  }
  .hero-slider-grid-container .hero-slider-container {
    grid-column: 1/-1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    width: 100%;
    height: 100%;
  }
  .hero-slider-grid-container .hero-slider-container .img-container {
    height: 25rem;
  }
  .hero-slider-grid-container .txt-container {
    position: static;
    grid-column: 1/-1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .hero-slider-grid-container .txt-container h1 {
    width: 100%;
    margin: 0 auto;
    font-size: 1.8rem;
    max-width: 85%;
  }
  .hero-slider-grid-container .txt-container::after {
    content: "";
    background: #fff;
    border: 1px solid #fff;
    width: 12rem;
    margin: 0 auto;
    margin-top: 1rem;
    display: block;
  }
}

.bg-body-video,
.bg-footer-video {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid-parent {
  margin: 0 !important;
  padding-block: 2rem;
}

.grid-parent.grid-parent-txt-right {
  background-color: #de2e2e;
}

.grid-parent.grid-parent-txt-left {
  background-color: #1b1b1b;
}

.grid-container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 5rem 5%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  -ms-grid-rows: 90vh;
      grid-template-rows: 90vh;
  gap: 2rem;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.grid-container .txt-container {
  letter-spacing: 0.3rem;
  padding-bottom: 5rem;
}

.grid-container .txt-container h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 3rem 0;
  position: relative;
}

.grid-container .txt-container p {
  max-width: 20rem;
  letter-spacing: 0.2rem;
  line-height: 1.3;
}

.grid-container .img-container {
  height: 100%;
  max-width: 100%;
  position: relative;
}

.grid-container .img-container img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 800px;
  height: 100%;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}

@media (max-width: 650px) {
  .grid-container {
    padding-inline: 0;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto 25rem;
        grid-template-rows: auto 25rem;
    gap: 2rem;
  }
  .grid-container .txt-container {
    grid-column: 1/-1 !important;
    -ms-grid-row: 1 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 1/2 !important;
    text-align: center !important;
    padding: 0;
  }
  .grid-container .txt-container h2 {
    margin: 1rem 0;
  }
  .grid-container .txt-container h2::before, .grid-container .txt-container h2::after {
    display: none;
  }
  .grid-container .txt-container p {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .grid-container .txt-container::after {
    content: "";
    border: 1px solid #fff;
    width: 12rem;
    background: #fff;
    display: inline-block;
    margin-top: 1rem;
  }
  .grid-container .img-container {
    grid-column: 1/-1 !important;
    grid-row: 2/-1 !important;
    max-width: revert;
  }
  .grid-container .img-container::after {
    z-index: 2 !important;
    display: none;
  }
  .grid-container .img-container img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.grid-container.creativity {
  padding-bottom: 0;
}

.grid-container-txt-left .txt-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  text-align: left;
  -webkit-transform: translateX(-30%);
          transform: translateX(-30%);
}

.grid-container-txt-left .txt-container h2::before {
  content: "";
  position: absolute;
  left: -2rem;
  height: 150%;
  top: -25%;
  width: 1px;
  background: #fff;
}

.grid-container-txt-left .img-container {
  grid-column: 2/-1;
  grid-row: 1/-1;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}

.grid-container-txt-left .img-container::after {
  content: "";
  position: absolute;
  width: 85vw;
  max-width: 1400px;
  height: 5rem;
  background: #fff;
  z-index: 0;
  bottom: -2rem;
  right: 0;
}

.grid-container-txt-right .txt-container {
  grid-column: 2/-1;
  grid-row: 1/-1;
  text-align: right;
  -webkit-transform: translateX(30%);
          transform: translateX(30%);
}

.grid-container-txt-right .txt-container h2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 150%;
  top: -25%;
  background: #fff;
  right: -1rem;
}

.grid-container-txt-right .txt-container > * {
  margin-left: auto;
}

.grid-container-txt-right .img-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}

.grid-container-txt-right .img-container::after {
  content: "";
  position: absolute;
  width: 85vw;
  max-width: 1400px;
  height: 5rem;
  background: #fff;
  z-index: 0;
  bottom: -2rem;
  left: 0;
}

.img-with-border .img-container {
  width: 100vw;
  height: 95vmin;
  margin: 0 auto;
  position: relative;
}

.img-with-border .img-container img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.img-with-border .img-container::before, .img-with-border .img-container::after {
  content: "";
  position: absolute;
  width: 70%;
  height: 5rem;
  background: #fff;
  z-index: 10;
}

.img-with-border .img-container::before {
  top: -2rem;
  right: 0;
}

.img-with-border .img-container::after {
  bottom: -2rem;
  left: 0;
}

@media (max-width: 650px) {
  .img-with-border .img-container {
    height: initial;
  }
  .img-with-border .img-container::before, .img-with-border .img-container::after {
    height: 2rem;
  }
  .img-with-border .img-container::before {
    top: -1rem;
  }
  .img-with-border .img-container::after {
    bottom: -1rem;
  }
}

.display-out > * {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  pointer-events: none !important;
  opacity: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
}

.mouse-tracker {
  width: 5rem;
  height: 5rem;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-transform: translate(-43%, -50%);
          transform: translate(-43%, -50%);
  border-radius: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.1s cubic-bezier(0, 0.21, 0.25, 1);
  transition: 0.1s cubic-bezier(0, 0.21, 0.25, 1);
  z-index: 1;
  will-change: auto;
  pointer-events: none;
}

@media (max-width: 770px) {
  .mouse-tracker {
    display: none;
  }
}

.shrink-item {
  width: 2rem;
  height: 2rem;
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
}

#comming-soon-area {
  background-color: #de2e2e;
  width: 100vw;
  height: 100vh;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

#comming-soon-area h1 {
  font-size: 5rem;
  text-align: center;
  max-width: 90%;
}

.add-transition {
  -webkit-transition: 0.1s ease-out clip-path;
  transition: 0.1s ease-out clip-path;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */
/* This one works! */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.quote-container {
  padding: 2rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  max-width: 30ch;
  letter-spacing: normal;
}

@media (max-width: 750px) {
  .quote-container {
    margin-inline: auto;
  }
}

.social-links-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: clamp(0.2rem, 5vw, 0.5rem);
}

.social-links-container .social-link-item {
  width: 100%;
  height: 100%;
  max-width: 2.5rem;
}

.social-links-container .social-link-item img {
  -o-object-fit: contain;
     object-fit: contain;
}

address {
  font-style: normal;
  font-weight: normal;
}

.slick-dots {
  bottom: 2.5rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.slick-dots li button {
  background-color: #fff;
  color: transparent;
  border-radius: 100%;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  display: inline-block;
}

.slick-dots li button::before {
  display: none;
  color: transparent;
  background: transparent;
}

.slick-dots li.slick-active button {
  width: 27px;
  background-color: #de2e2e;
  border-radius: 5px;
}

#not-found-page {
  display: -ms-grid;
  display: grid;
  place-items: center;
  place-content: center;
  min-height: 100vh;
}

#not-found-page h1 {
  margin: 0;
  margin-bottom: 1rem;
}
