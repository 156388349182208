@import url(https://fonts.googleapis.com/css2?family=Andika+New+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.ham-icon-container {
  display: block;
  height: 30px;
  overflow: hidden;
}

.ham-icon-container img {
  height: 30px;
  width: 30px;
  display: block;
  object-fit: contain;
  transition: 0.3s ease;
}

.ham-icon-container img:hover, .ham-icon-container img:focus {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.ham-icon-container img ~ img {
  margin-top: 1rem;
}

@media (min-width: 900px) {
  .ham-icon-container {
    display: none;
  }
}

.ham-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: 0.3s ease;
  display: block;
}

.ham-menu svg {
  width: 3rem;
  height: 3rem;
}

.ham-menu.open {
  margin-top: calc((100% + 1rem) * -1);
}

/* No CSS */
@-webkit-keyframes scroll-fade-up {
  from {
    top: 10%;
  }
  to {
    top: 100%;
    opacity: 0;
  }
}

@keyframes scroll-fade-up {
  from {
    top: 10%;
  }
  to {
    top: 100%;
    opacity: 0;
  }
}

.translate-out {
  -webkit-transform: translateY(-100%) !important;
          transform: translateY(-100%) !important;
  opacity: 0 !important;
  pointer-events: none;
}

.fade-in-up {
  transition: 0.3s ease;
  -webkit-transform: translateY(3rem);
          transform: translateY(3rem);
  opacity: 0;
}

@-webkit-keyframes fade-in-up {
  to {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 1;
  }
}

@keyframes fade-in-up {
  to {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 1;
  }
}

.fade-in {
  transition: 0.05 ease !important;
  opacity: 0;
  -webkit-transform: translateX(10%);
          transform: translateX(10%);
}

@-webkit-keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.fade-out-display-none {
  transition: 0.3s ease;
}

@-webkit-keyframes fade-out-display-none {
  to {
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }
}

@keyframes fade-out-display-none {
  to {
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }
}

@-webkit-keyframes error-occured {
  from {
    color: orange;
    background: red;
  }
  to {
    color: red;
    background: white;
  }
}

@keyframes error-occured {
  from {
    color: orange;
    background: red;
  }
  to {
    color: red;
    background: white;
  }
}

@-webkit-keyframes fade-left {
  to {
    opacity: 0;
    -webkit-transform: scale(3) translateX(-3rem);
            transform: scale(3) translateX(-3rem);
  }
}

@keyframes fade-left {
  to {
    opacity: 0;
    -webkit-transform: scale(3) translateX(-3rem);
            transform: scale(3) translateX(-3rem);
  }
}

@-webkit-keyframes fade-right {
  to {
    opacity: 0;
    -webkit-transform: scale(3) translateX(3rem);
            transform: scale(3) translateX(3rem);
  }
}

@keyframes fade-right {
  to {
    opacity: 0;
    -webkit-transform: scale(3) translateX(3rem);
            transform: scale(3) translateX(3rem);
  }
}

.fade-out {
  opacity: 0;
  pointer-events: none;
}

@-webkit-keyframes fade-out {
  to {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
    pointer-events: none;
  }
}

@-webkit-keyframes clip-up {
  0% {
    -webkit-clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
            clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 25%, 0 85%, 100% 85%, 100% 25%);
            clip-path: polygon(0 25%, 0 85%, 100% 85%, 100% 25%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
            clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
  }
}

@keyframes clip-up {
  0% {
    -webkit-clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
            clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 25%, 0 85%, 100% 85%, 100% 25%);
            clip-path: polygon(0 25%, 0 85%, 100% 85%, 100% 25%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
            clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
  }
}

@-webkit-keyframes clip-circle {
  0% {
    -webkit-clip-path: circle(0% at 0 0);
            clip-path: circle(0% at 0 0);
  }
  50% {
    -webkit-clip-path: circle(100% at 50% 50%);
            clip-path: circle(100% at 50% 50%);
  }
  100% {
    -webkit-clip-path: circle(0% at 100% 100%);
            clip-path: circle(0% at 100% 100%);
  }
}

@keyframes clip-circle {
  0% {
    -webkit-clip-path: circle(0% at 0 0);
            clip-path: circle(0% at 0 0);
  }
  50% {
    -webkit-clip-path: circle(100% at 50% 50%);
            clip-path: circle(100% at 50% 50%);
  }
  100% {
    -webkit-clip-path: circle(0% at 100% 100%);
            clip-path: circle(0% at 100% 100%);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: skew(0deg, 0deg);
            transform: skew(0deg, 0deg);
  }
  50% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  100% {
    -webkit-transform: skew(0deg, 0deg);
            transform: skew(0deg, 0deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: skew(0deg, 0deg);
            transform: skew(0deg, 0deg);
  }
  50% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  100% {
    -webkit-transform: skew(0deg, 0deg);
            transform: skew(0deg, 0deg);
  }
}

@-webkit-keyframes logo-stripe {
  0% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    left: calc((5000px - 100vw) * -1);
    opacity: 0;
  }
}

@keyframes logo-stripe {
  0% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    left: calc((5000px - 100vw) * -1);
    opacity: 0;
  }
}

.translate-x-reset {
  opacity: 0;
}

@-webkit-keyframes translate-x-reset {
  from {
    opacity: 0;
    -webkit-transform: translateX(25%);
            transform: translateX(25%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes translate-x-reset {
  from {
    opacity: 0;
    -webkit-transform: translateX(25%);
            transform: translateX(25%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
}

.translate-y-reset {
  opacity: 0;
}

@-webkit-keyframes translate-y-reset {
  from {
    opacity: 0;
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes translate-y-reset {
  from {
    opacity: 0;
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 750px) {
  html {
    font-size: 95%;
  }
}

@media (max-width: 650px) {
  html {
    font-size: 85%;
  }
}

@media (max-width: 550px) {
  html {
    font-size: 75%;
  }
}

[data-custom-smooth-scroll] {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

section,
article {
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  scroll-snap-align: center;
}

.showHeader {
  -webkit-transform: unset;
          transform: unset;
  background: linear-gradient(-180deg, #000000a6, transparent);
  pointer-events: all;
}

.reveal-header {
  -webkit-transform: unset;
          transform: unset;
  pointer-events: all;
  background: linear-gradient(-180deg, #00000045, transparent);
}

.header-with-bg {
  background-color: #000 !important;
}

@media (max-width: 650px) {
  .header-with-bg {
    background-color: revert;
  }
}

.primary-font {
  text-align: inherit;
  color: inherit;
  font-weight: 800;
  font-size: clamp(1.8rem, 5vw, 3rem);
  margin-bottom: 0.4em;
}

.secondary-font {
  text-align: inherit;
  color: inherit;
  font-weight: 600;
  font-size: clamp(1.6rem, 5vw, 2rem);
  margin-bottom: 0.3em;
}

.tertiary-font {
  text-align: inherit;
  color: inherit;
  font-weight: 600;
  font-size: clamp(1.4rem, 5vw, 1.4rem);
  margin-bottom: 0.2em;
}

.btn {
  display: inline-block;
  cursor: pointer;
  padding: 0.5em 2.5em;
  transition: 0.5s ease;
  border-radius: 10px;
  outline: none;
  border: transparent 1px solid;
  color: #fff;
  background: #de2e2e;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 400;
  font-family: inherit;
  box-shadow: none;
  position: relative;
  z-index: 1;
}

.btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 0%;
  transition: 0.5s ease;
  z-index: -1;
  background: transparent;
  border-radius: inherit;
}

.btn:hover, .btn:focus, .btn:active {
  color: #de2e2e;
  -webkit-transform: revert;
          transform: revert;
}

.btn:hover::before, .btn:focus::before, .btn:active::before {
  width: 100%;
  background: #fff;
}

@media (min-width: 650px) {
  .nav-link-home {
    display: none;
  }
}

.fixed-mobile-logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

@media (min-width: 650px) {
  .fixed-mobile-logo {
    display: none;
  }
}

.progress-bar-contianer {
  pointer-events: none;
  height: 85vh;
  width: 3rem;
  position: fixed;
  left: 0rem;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  z-index: 100;
}

.progress-bar-contianer #progress-bar {
  width: 1px;
  background: #de2e2e;
  transition: 0.3s ease-out;
  will-change: auto;
  z-index: 10;
}

.progress-bar-contianer .progress-line-bg {
  width: 1px;
  height: 100%;
  background: #fff;
  transition: 0.3s ease-out;
  will-change: auto;
  position: absolute;
}

.progress-bar-contianer .progress-logo {
  width: 3rem;
  height: 3rem;
  z-index: 100;
  position: fixed;
  will-change: auto;
  transition: 0.3s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
  cursor: not-allowed;
}

.custom-small-heading {
  margin: 4rem;
  letter-spacing: 0.4rem;
  display: inline-block;
  position: relative;
  font-weight: normal;
  margin-top: 7rem;
}

.custom-small-heading::after {
  position: absolute;
  content: "";
  width: 9rem;
  height: 1px;
  background: #fff;
  bottom: -0.5rem;
  right: -3rem;
}

.custom-hero-heading-container {
  display: block;
  margin: 0 auto;
}

.custom-hero-heading-container h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.4rem;
  width: -webkit-max-content;
  width: max-content;
  position: relative;
}

.custom-hero-heading-container .line-hero {
  position: absolute;
  width: 110%;
  bottom: -25%;
  z-index: -1;
  top: 50%;
  left: 50%;
  background: #000;
  -webkit-transform: translate(-50%, 9%);
          transform: translate(-50%, 9%);
  height: 2rem;
}

.rich-quote-container-container {
  text-align: center;
}

.rich-quote-container-container blockquote {
  padding: 6rem 3rem;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 1.5rem;
  letter-spacing: 0.4rem;
  line-height: 1.2;
}

.rich-quote-container-container blockquote::after, .rich-quote-container-container blockquote::before {
  content: "";
  width: 10rem;
  background: #fff;
  border: 1px solid #fff;
  display: block;
  margin: 0 auto;
}

.rich-quote-container-container blockquote::after {
  margin-top: 1rem;
}

.rich-quote-container-container blockquote::before {
  margin-bottom: 1rem;
}

.hero-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20rem 1fr;
      grid-template-columns: 20rem 1fr;
  width: 100vw;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5rem;
  position: relative;
}

.hero-grid-container .txt-container {
  position: absolute;
  top: 40%;
  left: 5rem;
  letter-spacing: 0.3rem;
  font-weight: 400;
}

.hero-grid-container .txt-container h1 {
  font-weight: 400;
  max-width: 60rem;
  font-size: 2.2rem;
}

.hero-grid-container .img-container {
  grid-column: 2/-1;
}

.hero-grid-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 650px) {
  .hero-grid-container {
    display: block;
    padding: 10rem 0;
    padding-top: 0;
    padding-bottom: 5rem;
    position: relative;
    height: auto;
  }
  .hero-grid-container .img-container {
    height: 40rem;
    width: 100%;
  }
  .hero-grid-container .txt-container {
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .hero-grid-container .txt-container h1 {
    max-width: none;
    max-width: initial;
    width: 100%;
    text-align: center;
  }
  .hero-grid-container .txt-container h1::after {
    content: "";
    display: block;
    width: 13rem;
    margin: 0 auto;
    border: 1px solid #fff;
    background-color: #fff;
    bottom: -1rem;
    position: relative;
  }
}

.hero-slider-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20rem 65vw;
      grid-template-columns: 20rem 65vw;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  width: 100vw;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5rem;
}

.hero-slider-grid-container .txt-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  position: absolute;
  top: 40%;
  left: 5rem;
  letter-spacing: 0.3rem;
  font-weight: 400;
}

.hero-slider-grid-container .txt-container h1 {
  font-weight: 400;
  width: 75%;
  max-width: 80rem;
  font-size: 2.2rem;
}

.hero-slider-grid-container .hero-slider-container {
  grid-column: 2/-1;
  grid-row: 1/-1;
}

.hero-slider-grid-container .hero-slider-container .img-container {
  width: 100%;
  height: 100%;
}

.hero-slider-grid-container .hero-slider-container .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

@media (max-width: 650px) {
  .hero-slider-grid-container {
    height: auto;
    padding: 5rem 0;
    -ms-grid-columns: 100vw;
        grid-template-columns: 100vw;
    -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    -webkit-align-items: stretch;
            align-items: stretch;
    grid-gap: 5rem;
    gap: 5rem;
  }
  .hero-slider-grid-container .hero-slider-container {
    grid-column: 1/-1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    width: 100%;
    height: 100%;
  }
  .hero-slider-grid-container .hero-slider-container .img-container {
    height: 25rem;
  }
  .hero-slider-grid-container .txt-container {
    position: static;
    grid-column: 1/-1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .hero-slider-grid-container .txt-container h1 {
    width: 100%;
    margin: 0 auto;
    font-size: 1.8rem;
    max-width: 85%;
  }
  .hero-slider-grid-container .txt-container::after {
    content: "";
    background: #fff;
    border: 1px solid #fff;
    width: 12rem;
    margin: 0 auto;
    margin-top: 1rem;
    display: block;
  }
}

.bg-body-video,
.bg-footer-video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid-parent {
  margin: 0 !important;
  padding-block: 2rem;
}

.grid-parent.grid-parent-txt-right {
  background-color: #de2e2e;
}

.grid-parent.grid-parent-txt-left {
  background-color: #1b1b1b;
}

.grid-container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 5rem 5%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  -ms-grid-rows: 90vh;
      grid-template-rows: 90vh;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.grid-container .txt-container {
  letter-spacing: 0.3rem;
  padding-bottom: 5rem;
}

.grid-container .txt-container h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 3rem 0;
  position: relative;
}

.grid-container .txt-container p {
  max-width: 20rem;
  letter-spacing: 0.2rem;
  line-height: 1.3;
}

.grid-container .img-container {
  height: 100%;
  max-width: 100%;
  position: relative;
}

.grid-container .img-container img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 800px;
  height: 100%;
  object-position: center;
  object-fit: cover;
  z-index: 2;
}

@media (max-width: 650px) {
  .grid-container {
    padding-inline: 0;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto 25rem;
        grid-template-rows: auto 25rem;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .grid-container .txt-container {
    grid-column: 1/-1 !important;
    -ms-grid-row: 1 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 1/2 !important;
    text-align: center !important;
    padding: 0;
  }
  .grid-container .txt-container h2 {
    margin: 1rem 0;
  }
  .grid-container .txt-container h2::before, .grid-container .txt-container h2::after {
    display: none;
  }
  .grid-container .txt-container p {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .grid-container .txt-container::after {
    content: "";
    border: 1px solid #fff;
    width: 12rem;
    background: #fff;
    display: inline-block;
    margin-top: 1rem;
  }
  .grid-container .img-container {
    grid-column: 1/-1 !important;
    grid-row: 2/-1 !important;
    max-width: revert;
  }
  .grid-container .img-container::after {
    z-index: 2 !important;
    display: none;
  }
  .grid-container .img-container img {
    object-fit: cover;
  }
}

.grid-container.creativity {
  padding-bottom: 0;
}

.grid-container-txt-left .txt-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  text-align: left;
  -webkit-transform: translateX(-30%);
          transform: translateX(-30%);
}

.grid-container-txt-left .txt-container h2::before {
  content: "";
  position: absolute;
  left: -2rem;
  height: 150%;
  top: -25%;
  width: 1px;
  background: #fff;
}

.grid-container-txt-left .img-container {
  grid-column: 2/-1;
  grid-row: 1/-1;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}

.grid-container-txt-left .img-container::after {
  content: "";
  position: absolute;
  width: 85vw;
  max-width: 1400px;
  height: 5rem;
  background: #fff;
  z-index: 0;
  bottom: -2rem;
  right: 0;
}

.grid-container-txt-right .txt-container {
  grid-column: 2/-1;
  grid-row: 1/-1;
  text-align: right;
  -webkit-transform: translateX(30%);
          transform: translateX(30%);
}

.grid-container-txt-right .txt-container h2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 150%;
  top: -25%;
  background: #fff;
  right: -1rem;
}

.grid-container-txt-right .txt-container > * {
  margin-left: auto;
}

.grid-container-txt-right .img-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}

.grid-container-txt-right .img-container::after {
  content: "";
  position: absolute;
  width: 85vw;
  max-width: 1400px;
  height: 5rem;
  background: #fff;
  z-index: 0;
  bottom: -2rem;
  left: 0;
}

.img-with-border .img-container {
  width: 100vw;
  height: 95vmin;
  margin: 0 auto;
  position: relative;
}

.img-with-border .img-container img {
  object-fit: contain;
  object-position: center;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.img-with-border .img-container::before, .img-with-border .img-container::after {
  content: "";
  position: absolute;
  width: 70%;
  height: 5rem;
  background: #fff;
  z-index: 10;
}

.img-with-border .img-container::before {
  top: -2rem;
  right: 0;
}

.img-with-border .img-container::after {
  bottom: -2rem;
  left: 0;
}

@media (max-width: 650px) {
  .img-with-border .img-container {
    height: auto;
    height: initial;
  }
  .img-with-border .img-container::before, .img-with-border .img-container::after {
    height: 2rem;
  }
  .img-with-border .img-container::before {
    top: -1rem;
  }
  .img-with-border .img-container::after {
    bottom: -1rem;
  }
}

.display-out > * {
  transition: 0.3s ease;
  pointer-events: none !important;
  opacity: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
}

.mouse-tracker {
  width: 5rem;
  height: 5rem;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-transform: translate(-43%, -50%);
          transform: translate(-43%, -50%);
  border-radius: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transition: 0.1s cubic-bezier(0, 0.21, 0.25, 1);
  z-index: 1;
  will-change: auto;
  pointer-events: none;
}

@media (max-width: 770px) {
  .mouse-tracker {
    display: none;
  }
}

.shrink-item {
  width: 2rem;
  height: 2rem;
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
}

#comming-soon-area {
  background-color: #de2e2e;
  width: 100vw;
  height: 100vh;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

#comming-soon-area h1 {
  font-size: 5rem;
  text-align: center;
  max-width: 90%;
}

.add-transition {
  transition: 0.1s ease-out clip-path;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */
/* This one works! */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.quote-container {
  padding: 2rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  max-width: 30ch;
  letter-spacing: normal;
}

@media (max-width: 750px) {
  .quote-container {
    margin-inline: auto;
  }
}

.social-links-container {
  display: -webkit-flex;
  display: flex;
  grid-gap: clamp(0.2rem, 5vw, 0.5rem);
  gap: clamp(0.2rem, 5vw, 0.5rem);
}

.social-links-container .social-link-item {
  width: 100%;
  height: 100%;
  max-width: 2.5rem;
}

.social-links-container .social-link-item img {
  object-fit: contain;
}

address {
  font-style: normal;
  font-weight: normal;
}

.slick-dots {
  bottom: 2.5rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.slick-dots li button {
  background-color: #fff;
  color: transparent;
  border-radius: 100%;
  transition: 0.3s ease;
  display: inline-block;
}

.slick-dots li button::before {
  display: none;
  color: transparent;
  background: transparent;
}

.slick-dots li.slick-active button {
  width: 27px;
  background-color: #de2e2e;
  border-radius: 5px;
}

#not-found-page {
  display: -ms-grid;
  display: grid;
  place-items: center;
  place-content: center;
  min-height: 100vh;
}

#not-found-page h1 {
  margin: 0;
  margin-bottom: 1rem;
}

body {
  font-family: "Montserrat", "Andika New Basic", sans-serif;
  color: #fff;
  background-color: #111;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}

a {
  text-decoration: none;
  color: #fff;
  transition: 0.2s ease;
}

a:hover, a:focus {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

h1,
h2 {
  font-family: "Rubik", sans-serif;
}

.logo-container {
  transition: 0.3s ease;
  max-width: 6rem;
  display: block;
}

.mobile-header {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2001;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
  -webkit-align-items: center;
          align-items: center;
  display: none;
}

@media (max-width: 650px) {
  .mobile-header {
    display: -webkit-flex;
    display: flex;
  }
}

header {
  width: 100%;
  position: fixed;
  top: 0rem;
  transition: 0.3s ease;
  z-index: 999;
  will-change: auto;
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
  pointer-events: none;
  padding-block: 1rem;
}

header nav {
  transition: 0.3s ease;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 3rem;
  z-index: 999;
}

header nav ul {
  display: -webkit-flex;
  display: flex;
  padding: 0 2rem;
  list-style: none;
  grid-gap: 3rem;
  gap: 3rem;
  text-transform: uppercase;
  font-size: 1.2rem;
}

header nav ul li {
  transition: 0.3s ease;
}

header nav ul li:hover, header nav ul li:focus {
  border-bottom: 1px solid #fff;
}

header a {
  color: #fff;
  display: inline-block;
  transition: 0.1s ease;
  font-weight: 500;
  font-size: 1rem;
}

header a:hover, header a:focus {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

@media (max-width: 650px) {
  header {
    padding-block: 0;
  }
  header nav {
    padding: 0;
    background: #de2e2e;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    min-height: 100vh;
    text-align: center;
  }
  header nav .logo-container {
    width: 8rem;
    display: none;
  }
  header nav ul {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0;
  }
  header nav ul li a {
    font-size: 2rem;
  }
}

main {
  font-family: "Montserrat", "Andika New Basic", sans-serif;
  width: 100vw;
  overflow-x: hidden;
}

main #hero {
  width: 100vw;
}

main #hero .hero-slider-container .slider-item {
  width: 100vw;
  height: 100vh;
  position: relative;
}

main #hero .hero-slider-container .slider-item .img-container {
  width: 100vw;
  height: 100vh;
}

main #hero .hero-slider-container .slider-item .img-container img {
  position: absolute;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
}

main #hero .hero-slider-container .slider-item .txt-container {
  position: absolute;
  display: block;
  margin: 0 auto;
  top: 20%;
  left: 15%;
}

main #hero .hero-slider-container .slider-item .txt-container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.3rem;
  width: -webkit-max-content;
  width: max-content;
  max-width: 20ch;
  position: relative;
}

main #hero .hero-slider-container .slider-item-1 .img-container {
  position: absolute;
  top: 10rem;
  width: 100%;
  height: 20rem;
}

main #hero .hero-slider-container .slider-item-1 .img-container img {
  max-width: 30rem;
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
}

main #hero .hero-slider-container .slider-item-1 .txt-container {
  top: 25rem;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
}

main #hero .hero-slider-container .slider-item-1 .txt-container h2 {
  width: -webkit-max-content;
  width: max-content;
  max-width: unset;
}

@media (max-width: 650px) {
  main #hero .hero-slider-container .slider-item {
    height: 50rem;
    text-align: center;
    position: relative;
  }
  main #hero .hero-slider-container .slider-item .img-container {
    position: relative;
    height: 100%;
  }
  main #hero .hero-slider-container .slider-item .img-container img {
    height: 100%;
    width: 100%;
    position: unset;
    -webkit-transform: unset;
            transform: unset;
  }
  main #hero .hero-slider-container .slider-item .txt-container {
    text-align: left;
    -webkit-transform: unset;
            transform: unset;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  main #hero .hero-slider-container .slider-item .txt-container h2 {
    padding-left: 1rem;
    font-size: 3rem;
    margin: 0 auto;
    width: unset;
    max-width: 100%;
    line-height: unset;
  }
  main #hero .hero-slider-container .slider-item-1 .img-container {
    height: -webkit-max-content;
    height: max-content;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  main #hero .hero-slider-container .slider-item-1 .img-container img {
    max-width: 12rem;
  }
  main #hero .hero-slider-container .slider-item-1 .txt-container {
    bottom: 15rem;
    -webkit-transform: translate(-50%, 5rem);
            transform: translate(-50%, 5rem);
    width: 100%;
  }
}

main #hero .hero-buttons-container {
  display: none;
  width: 100vw;
  position: relative;
  bottom: 3rem;
}

main #hero .hero-buttons-container .buttons {
  position: relative;
}

main #hero .hero-buttons-container .buttons button {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  width: 4rem;
  height: 4rem;
  outline: none;
  border: none;
  font-size: 0.5rem;
  font-weight: 100;
  border-radius: 50%;
  background: #fff;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 2px solid #fff;
  z-index: 100;
  cursor: pointer;
  transition: 0.2s ease;
}

main #hero .hero-buttons-container .buttons button:hover, main #hero .hero-buttons-container .buttons button:focus {
  opacity: 0.7;
  color: #fff;
}

main #hero .hero-buttons-container .buttons button img {
  width: 1.5rem;
  object-fit: contain;
}

main #hero .hero-buttons-container .buttons [aria-label="Previous"] {
  -webkit-transform: translate(-200%, -50%);
          transform: translate(-200%, -50%);
}

main #hero .hero-buttons-container .buttons [aria-label="Previous"]:hover, main #hero .hero-buttons-container .buttons [aria-label="Previous"]:focus {
  -webkit-transform: translate(-200%, -50%) scale(0.95);
          transform: translate(-200%, -50%) scale(0.95);
}

main #hero .hero-buttons-container .buttons [aria-label="Next"] {
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
}

main #hero .hero-buttons-container .buttons [aria-label="Next"]:hover, main #hero .hero-buttons-container .buttons [aria-label="Next"]:focus {
  -webkit-transform: translate(100%, -50%) scale(0.95);
          transform: translate(100%, -50%) scale(0.95);
}

main #hero .hero-buttons-container .buttons::after {
  background: #fff;
  content: "";
  height: 1px;
  width: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 99;
}

main #hero .slick-arrow {
  display: none !important;
}

main #services {
  min-height: 100vh;
  transition: 0.3s ease;
}

main #services article {
  margin: 5rem auto;
}

main #peoples {
  padding: 5rem 0;
  background: #000;
}

main #peoples .img-container {
  position: relative;
}

main #peoples .img-container p {
  position: absolute;
  top: 6rem;
  left: 10%;
  z-index: 10;
  width: 40vmax;
  font-size: 3rem;
}

@media (max-width: 650px) {
  main #peoples .img-container img {
    object-fit: cover;
  }
  main #peoples .img-container p {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
  }
  main #peoples .img-container p::after {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #fff;
    height: 0px;
    bottom: 0;
    top: -1rem;
    right: 0;
  }
}

#home {
  overflow-x: hidden;
}

#home .parallax-section .parallax-heading {
  position: relative;
  margin: 5%;
  max-width: -webkit-max-content;
  max-width: max-content;
  letter-spacing: 0.2em;
  font-size: 2rem;
}

#home .parallax-section .parallax-heading::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50%;
  background-color: #fff;
  bottom: -0.5rem;
  right: -3rem;
}

#home .parallax-section .parallax-container .parallax-item {
  height: 450px;
  position: relative;
}

#home .parallax-section .parallax-container .parallax-item img {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  transition: 0.3s ease;
}

#home .parallax-section .parallax-container .parallax-item .txt-container {
  position: absolute;
  top: 10%;
  padding-inline: 5%;
}

#home .parallax-section .parallax-container .parallax-item .txt-container h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

#home .parallax-section .parallax-container .parallax-item .txt-container p {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

#home .parallax-section .parallax-container .parallax-item:nth-child(even) .txt-container {
  top: revert;
  right: 0;
  bottom: 10%;
  padding-inline: 5%;
  text-align: right;
}

#home .parallax-section .home-end-buttons {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template: 1fr/ 1fr 1fr;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 800px;
  margin: 5rem auto;
}

#home .parallax-section .home-end-buttons .btn {
  max-width: 100%;
  -ms-grid-column-align: center;
      justify-self: center;
  background-color: #fff;
  color: #000;
  border: none;
  outline: none;
}

#home .parallax-section .home-end-buttons .btn:hover, #home .parallax-section .home-end-buttons .btn:focus {
  color: #fff;
}

#home .parallax-section .home-end-buttons .btn:hover::before, #home .parallax-section .home-end-buttons .btn:focus::before {
  background-color: #000;
  border: none;
  outline: none;
}

@media (max-width: 650px) {
  #home .parallax-section .home-end-buttons {
    -ms-grid-rows: 1fr 1fr;
    -ms-grid-columns: 1fr;
        grid-template: 1fr 1fr / 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

#about {
  width: 100vw;
}

#about article .grid-container-txt-left,
#about article .grid-container-txt-right {
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
}

#about article .grid-container-txt-left .txt-container,
#about article .grid-container-txt-right .txt-container {
  max-width: 35ch;
}

#about article .grid-container-txt-left .txt-container p,
#about article .grid-container-txt-right .txt-container p {
  max-width: 40ch;
}

#about article .grid-container-txt-left > .img-container,
#about article .grid-container-txt-right > .img-container {
  max-width: 100%;
}

#about article .grid-container-txt-right {
  -ms-grid-columns: 1fr auto;
      grid-template-columns: 1fr auto;
}

#contact {
  width: 100vw;
}

#contact .hero-slider-grid-container .txt-container {
  z-index: 1;
  position: revert;
}

#contact .hero-slider-grid-container .hero-slider-container {
  z-index: 0;
}

#contact .hero-slider-grid-container .hero-slider-container .slick-arrow {
  display: none !important;
}

#contact .tabs .tab {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5rem 0;
  grid-gap: 1rem;
  gap: 1rem;
}

#contact .tabs .tab button {
  padding: 1rem 3rem;
  background: no-repeat;
  outline: none;
  border: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: 0.1s ease-out;
  border-radius: 5px;
}

#contact .tabs .tab button:hover, #contact .tabs .tab button:focus {
  background: #fff;
  color: #000;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#contact .tabs form .form-item,
#contact .tabs form .checkboxes-container,
#contact .tabs form .form-item-submit {
  width: 40rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
}

#contact .tabs form .form-item input,
#contact .tabs form .form-item textarea,
#contact .tabs form .form-item button,
#contact .tabs form .checkboxes-container input,
#contact .tabs form .checkboxes-container textarea,
#contact .tabs form .checkboxes-container button,
#contact .tabs form .form-item-submit input,
#contact .tabs form .form-item-submit textarea,
#contact .tabs form .form-item-submit button {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
}

#contact .tabs form .form-item input::-webkit-input-placeholder,
#contact .tabs form .form-item textarea::-webkit-input-placeholder,
#contact .tabs form .form-item button::-webkit-input-placeholder,
#contact .tabs form .checkboxes-container input::-webkit-input-placeholder,
#contact .tabs form .checkboxes-container textarea::-webkit-input-placeholder,
#contact .tabs form .checkboxes-container button::-webkit-input-placeholder,
#contact .tabs form .form-item-submit input::-webkit-input-placeholder,
#contact .tabs form .form-item-submit textarea::-webkit-input-placeholder,
#contact .tabs form .form-item-submit button::-webkit-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input:-ms-input-placeholder,
#contact .tabs form .form-item textarea:-ms-input-placeholder,
#contact .tabs form .form-item button:-ms-input-placeholder,
#contact .tabs form .checkboxes-container input:-ms-input-placeholder,
#contact .tabs form .checkboxes-container textarea:-ms-input-placeholder,
#contact .tabs form .checkboxes-container button:-ms-input-placeholder,
#contact .tabs form .form-item-submit input:-ms-input-placeholder,
#contact .tabs form .form-item-submit textarea:-ms-input-placeholder,
#contact .tabs form .form-item-submit button:-ms-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input::-webkit-input-placeholder, #contact .tabs form .form-item textarea::-webkit-input-placeholder, #contact .tabs form .form-item button::-webkit-input-placeholder, #contact .tabs form .checkboxes-container input::-webkit-input-placeholder, #contact .tabs form .checkboxes-container textarea::-webkit-input-placeholder, #contact .tabs form .checkboxes-container button::-webkit-input-placeholder, #contact .tabs form .form-item-submit input::-webkit-input-placeholder, #contact .tabs form .form-item-submit textarea::-webkit-input-placeholder, #contact .tabs form .form-item-submit button::-webkit-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input:-ms-input-placeholder, #contact .tabs form .form-item textarea:-ms-input-placeholder, #contact .tabs form .form-item button:-ms-input-placeholder, #contact .tabs form .checkboxes-container input:-ms-input-placeholder, #contact .tabs form .checkboxes-container textarea:-ms-input-placeholder, #contact .tabs form .checkboxes-container button:-ms-input-placeholder, #contact .tabs form .form-item-submit input:-ms-input-placeholder, #contact .tabs form .form-item-submit textarea:-ms-input-placeholder, #contact .tabs form .form-item-submit button:-ms-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input::placeholder,
#contact .tabs form .form-item textarea::placeholder,
#contact .tabs form .form-item button::placeholder,
#contact .tabs form .checkboxes-container input::placeholder,
#contact .tabs form .checkboxes-container textarea::placeholder,
#contact .tabs form .checkboxes-container button::placeholder,
#contact .tabs form .form-item-submit input::placeholder,
#contact .tabs form .form-item-submit textarea::placeholder,
#contact .tabs form .form-item-submit button::placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item textarea,
#contact .tabs form .checkboxes-container textarea,
#contact .tabs form .form-item-submit textarea {
  height: 1.5rem;
  max-width: 100%;
  resize: vertical;
}

#contact .tabs form .form-item button,
#contact .tabs form .checkboxes-container button,
#contact .tabs form .form-item-submit button {
  padding: 1rem 3rem;
  border: none;
  background: #fff;
  color: #000;
  cursor: pointer;
  transition: 0.1s ease-out;
  border-radius: 5px;
}

#contact .tabs form .form-item button:hover, #contact .tabs form .form-item button:active, #contact .tabs form .form-item button:focus,
#contact .tabs form .checkboxes-container button:hover,
#contact .tabs form .checkboxes-container button:active,
#contact .tabs form .checkboxes-container button:focus,
#contact .tabs form .form-item-submit button:hover,
#contact .tabs form .form-item-submit button:active,
#contact .tabs form .form-item-submit button:focus {
  background: #000;
  color: #fff;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#contact .tabs form .form-item .check-box-item,
#contact .tabs form .checkboxes-container .check-box-item,
#contact .tabs form .form-item-submit .check-box-item {
  padding-bottom: 0.5rem;
}

#contact .tabs form .form-item .check-box-item label input,
#contact .tabs form .checkboxes-container .check-box-item label input,
#contact .tabs form .form-item-submit .check-box-item label input {
  margin-right: 0.5rem;
  width: revert;
}

#contact .tabs form .form-item .check-box-item:last-of-type,
#contact .tabs form .checkboxes-container .check-box-item:last-of-type,
#contact .tabs form .form-item-submit .check-box-item:last-of-type {
  padding-bottom: 0;
}

#contact .tabs form .form-item .message,
#contact .tabs form .checkboxes-container .message,
#contact .tabs form .form-item-submit .message {
  color: royalblue;
  font-size: 1rem;
  text-align: center;
}

#contact .tabs .start-project {
  -webkit-justify-content: center;
          justify-content: center;
  transition: 0.3s ease;
  height: auto;
}

#contact .tabs .join-team {
  letter-spacing: 0.3rem;
  text-align: center;
  transition: 0.3s ease;
  height: auto;
}

#contact .tabs .join-team .rich-txt {
  max-width: 1000px;
  line-height: 1.3;
  position: relative;
}

#contact .tabs .join-team .rich-txt::after, #contact .tabs .join-team .rich-txt::before {
  content: "";
  margin: -2rem auto;
  display: block;
  background: #fff;
  height: 1px;
  width: 20vw;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#contact .tabs .join-team .rich-txt::before {
  top: 0;
  width: 30vw;
}

#contact .tabs .join-team .rich-txt::after {
  bottom: 0;
}

#contact .tabs .join-team .rich-txt p {
  margin-top: 1rem;
  line-height: 1.3;
}

#contact .tabs .join-team .intersted h2 {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
  margin-bottom: 2rem;
}

#contact .tabs .join-team .intersted h2::after {
  bottom: 0;
  position: absolute;
  content: "";
  width: 70%;
  margin: -0.5rem auto;
  height: 1px;
  background: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#contact .tabs .join-team .jobs-container {
  letter-spacing: 0.3rem;
}

#contact .tabs .join-team .jobs-container h2 {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
}

#contact .tabs .join-team .jobs-container h2::after {
  bottom: 0;
  position: absolute;
  content: "";
  width: 70%;
  margin: -0.5rem auto;
  height: 1px;
  background: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#contact .tabs .join-team .jobs-container p {
  margin-top: 2rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item {
  margin: 1rem auto;
  max-width: 1000px;
  border-radius: 5px;
  height: -webkit-max-content;
  height: max-content;
  background: #fff;
  color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 2rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .txt-container {
  width: 17rem;
  color: #000;
  letter-spacing: 0.3rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .txt-container h3 {
  margin-bottom: 0.2rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .txt-container p {
  margin: 0;
  color: #ff0707;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .link-container a {
  color: #fff;
  background: #ff0707;
  border: #ff0707 1px solid;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: 0.1s ease-out;
  display: inline-block;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .link-container a:hover, #contact .tabs .join-team .jobs-container .jobs .job-item .link-container a:focus {
  background: #fff;
  color: #ff0707;
}

#contact .tabs .join-team > * {
  margin: 5rem auto;
}

#contact .contact-info-container {
  padding: 2rem 5%;
  padding-bottom: 3rem;
}

#contact .contact-info-container .info-card {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  color: #000;
  letter-spacing: 0.2em;
}

#contact .contact-info-container .info-card .grid-container {
  all: revert;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: auto auto;
      grid-template: 1fr / auto auto;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: stretch;
          align-items: stretch;
}

#contact .contact-info-container .info-card .grid-container .social-links-area {
  display: -ms-grid;
  display: grid;
  -webkit-align-content: space-between;
          align-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

#contact .contact-info-container .info-card .grid-container .social-links-area > * {
  margin-left: auto;
  width: -webkit-max-content;
  width: max-content;
}

#contact .contact-info-container .info-card .grid-container .icon-container img {
  max-width: 2.5rem;
}

@media (max-width: 650px) {
  #contact .contact-info-container .info-card .grid-container {
    -ms-grid-rows: auto auto;
    -ms-grid-columns: auto;
        grid-template: auto auto / auto;
    -webkit-justify-content: stretch;
            justify-content: stretch;
  }
  #contact .contact-info-container .info-card .grid-container .social-links-area > * {
    margin-left: revert;
    width: -webkit-max-content;
    width: max-content;
  }
}

@media (max-width: 650px) {
  #contact .tabs {
    min-height: 0;
    min-height: initial;
    height: auto;
  }
  #contact .tabs .tab {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  #contact .tabs form {
    width: 100vw;
  }
  #contact .tabs form .form-item,
  #contact .tabs form .form-item-submit,
  #contact .tabs form .checkboxes-container {
    width: 80%;
  }
  #contact .tabs .join-team .rich-txt,
  #contact .tabs .join-team .jobs-container {
    padding: 0 5%;
  }
}

#work .tags-buttons-container {
  margin: 5rem 5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
}

#work .tags-buttons-container .tag-item .btn {
  letter-spacing: 0.2em;
  max-width: 100%;
  text-transform: capitalize;
  padding: 0.5em 1.5em;
  width: -webkit-max-content;
  width: max-content;
  display: block;
  color: #de2e2e;
  background: #fff;
  border: none;
}

#work .tags-buttons-container .tag-item .btn:hover, #work .tags-buttons-container .tag-item .btn:focus {
  color: inherit;
  background: inherit;
  color: #de2e2e;
  background: #fff;
}

#work .tags-buttons-container .tag-item .btn:hover::before, #work .tags-buttons-container .tag-item .btn:focus::before {
  width: 0%;
  display: none;
  background: #ff0707;
}

#work .tags-buttons-container .tag-item .btn.active {
  color: #fff;
  -webkit-transform: revert;
          transform: revert;
  background: #ff0707;
}

#work .tags-buttons-container .tag-item .btn.active::before {
  display: none;
  width: 100%;
  background: #ff0707;
}

#work .works-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  -ms-grid-columns: (minmax(22rem, 1fr))[auto-fill];
      grid-template: auto/repeat(auto-fill, minmax(22rem, 1fr));
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 4rem;
  gap: 4rem;
  max-width: 1600px;
  margin: 0 auto;
  padding: 5%;
}

#work .works-container .work-item {
  letter-spacing: 0.2em;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -webkit-align-content: space-between;
          align-content: space-between;
}

#work .works-container .work-item .title {
  font-weight: bold;
  margin-top: 2rem;
  font-size: 1.2rem;
}

#work .works-container .work-item .tags {
  font-weight: lighter;
  margin-top: 0.8rem;
  text-transform: capitalize;
}

#selected-work-page {
  background-color: #1b1b1b;
  font-family: Montserrat;
  line-height: 1.4;
  font-size: 1.1rem;
}

#selected-work-page .two-column-grid {
  display: -ms-grid;
  display: grid;
  grid-gap: 0rem;
  gap: 0rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template: 1fr/ 1fr 1fr;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

#selected-work-page .two-column-grid .txt-container {
  max-width: 30ch;
  -ms-grid-column-align: center;
      justify-self: center;
  font-weight: 500;
}

@media (max-width: 650px) {
  #selected-work-page .two-column-grid {
    -ms-grid-rows: auto auto;
    -ms-grid-columns: 1fr;
        grid-template: auto auto/ 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }
  #selected-work-page .two-column-grid .txt-container {
    max-width: 80%;
    text-align: center;
  }
  #selected-work-page .two-column-grid .txt-container::after {
    position: relative;
    display: block;
    content: "";
    background-color: #fff;
    max-width: 15rem;
    width: 100vw;
    height: 2px;
    left: 50%;
    -webkit-transform: translate(-50%, 1rem);
            transform: translate(-50%, 1rem);
  }
}

#selected-work-page .dispay-img-txt-group p {
  padding: 4rem 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

#selected-work-page .dispay-img-txt-group p::after {
  position: relative;
  display: block;
  content: "";
  background-color: #fff;
  max-width: 15rem;
  width: 100vw;
  height: 2px;
  left: 50%;
  -webkit-transform: translate(-50%, 1rem);
          transform: translate(-50%, 1rem);
  margin-bottom: 1rem;
}

#selected-work-page .display-img-grid {
  display: -ms-grid;
  display: grid;
  padding: 1rem calc(min(5rem, 15%));
  padding-top: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template: 1fr/ 1fr 1fr;
}

@media (max-width: 650px) {
  #selected-work-page .display-img-grid {
    -ms-grid-rows: auto auto;
    -ms-grid-columns: 1fr;
        grid-template: auto auto/ 1fr;
  }
}

footer {
  background-color: #fff;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  color: #000;
  padding: 5rem 0;
  letter-spacing: 0.3rem;
}

footer .email {
  margin: 0 10rem;
  color: #ff0707;
  font-size: 2rem;
}

footer .email::after {
  display: inline-block;
  content: "";
  background: #000;
  height: 1px;
  width: 11rem;
  position: relative;
  right: 7rem;
  top: 1rem;
}

footer .info-container {
  margin: 3rem 10rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-align-items: center;
          align-items: center;
  max-width: 50rem;
}

footer .info-container .address-container {
  -ms-grid-row-align: start;
  -webkit-align-self: start;
          align-self: start;
}

footer .info-container .address-container h3 {
  margin-bottom: 1rem;
  font-weight: 500;
}

footer .info-container .address-container address {
  letter-spacing: 0.2rem;
  font-style: normal;
  font-weight: normal;
}

footer .info-container .links-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
}

footer .info-container .links-container .link-set {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

footer .info-container .links-container .link-set a {
  color: #000;
  margin-bottom: 1rem;
  font-weight: 500;
}

@media (max-width: 650px) {
  footer .email {
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
  }
  footer .email::after {
    right: 0;
    top: 0;
  }
  footer .info-container {
    margin: 3rem 1rem;
    -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
  }
  footer .info-container .links-container {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
  footer .info-container .links-container .link-set a {
    margin-bottom: 0.5rem;
  }
}

footer .social-links-container {
  padding-top: 1rem;
}

.gmaps-container {
  text-decoration: none;
  overflow: hidden;
  max-width: 100%;
  width: 100vw;
  position: relative;
}

@media (max-width: 650px) {
  #services > article {
    overflow-x: hidden;
  }
}

