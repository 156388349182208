body {
  font-family: "Montserrat", "Andika New Basic", sans-serif;
  color: #fff;
  background-color: #111;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

a {
  text-decoration: none;
  color: #fff;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

a:hover, a:focus {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

h1,
h2 {
  font-family: "Rubik", sans-serif;
}

.logo-container {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  max-width: 6rem;
  display: block;
}

.mobile-header {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2001;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: none;
}

@media (max-width: 650px) {
  .mobile-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

header {
  width: 100%;
  position: fixed;
  top: 0rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  z-index: 999;
  will-change: auto;
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
  pointer-events: none;
  padding-block: 1rem;
}

header nav {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 3rem;
  z-index: 999;
}

header nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 2rem;
  list-style: none;
  gap: 3rem;
  text-transform: uppercase;
  font-size: 1.2rem;
}

header nav ul li {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

header nav ul li:hover, header nav ul li:focus {
  border-bottom: 1px solid #fff;
}

header a {
  color: #fff;
  display: inline-block;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
  font-weight: 500;
  font-size: 1rem;
}

header a:hover, header a:focus {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

@media (max-width: 650px) {
  header {
    padding-block: 0;
  }
  header nav {
    padding: 0;
    background: #de2e2e;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    min-height: 100vh;
    text-align: center;
  }
  header nav .logo-container {
    width: 8rem;
    display: none;
  }
  header nav ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    padding: 0;
  }
  header nav ul li a {
    font-size: 2rem;
  }
}

main {
  font-family: "Montserrat", "Andika New Basic", sans-serif;
  width: 100vw;
  overflow-x: hidden;
}

main #hero {
  width: 100vw;
}

main #hero .hero-slider-container .slider-item {
  width: 100vw;
  height: 100vh;
  position: relative;
}

main #hero .hero-slider-container .slider-item .img-container {
  width: 100vw;
  height: 100vh;
}

main #hero .hero-slider-container .slider-item .img-container img {
  position: absolute;
  display: block;
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
}

main #hero .hero-slider-container .slider-item .txt-container {
  position: absolute;
  display: block;
  margin: 0 auto;
  top: 20%;
  left: 15%;
}

main #hero .hero-slider-container .slider-item .txt-container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.3rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 20ch;
  position: relative;
}

main #hero .hero-slider-container .slider-item-1 .img-container {
  position: absolute;
  top: 10rem;
  width: 100%;
  height: 20rem;
}

main #hero .hero-slider-container .slider-item-1 .img-container img {
  max-width: 30rem;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
}

main #hero .hero-slider-container .slider-item-1 .txt-container {
  top: 25rem;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
}

main #hero .hero-slider-container .slider-item-1 .txt-container h2 {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: unset;
}

@media (max-width: 650px) {
  main #hero .hero-slider-container .slider-item {
    height: 50rem;
    text-align: center;
    position: relative;
  }
  main #hero .hero-slider-container .slider-item .img-container {
    position: relative;
    height: 100%;
  }
  main #hero .hero-slider-container .slider-item .img-container img {
    height: 100%;
    width: 100%;
    position: unset;
    -webkit-transform: unset;
            transform: unset;
  }
  main #hero .hero-slider-container .slider-item .txt-container {
    text-align: left;
    -webkit-transform: unset;
            transform: unset;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  main #hero .hero-slider-container .slider-item .txt-container h2 {
    padding-left: 1rem;
    font-size: 3rem;
    margin: 0 auto;
    width: unset;
    max-width: 100%;
    line-height: unset;
  }
  main #hero .hero-slider-container .slider-item-1 .img-container {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  main #hero .hero-slider-container .slider-item-1 .img-container img {
    max-width: 12rem;
  }
  main #hero .hero-slider-container .slider-item-1 .txt-container {
    bottom: 15rem;
    -webkit-transform: translate(-50%, 5rem);
            transform: translate(-50%, 5rem);
    width: 100%;
  }
}

main #hero .hero-buttons-container {
  display: none;
  width: 100vw;
  position: relative;
  bottom: 3rem;
}

main #hero .hero-buttons-container .buttons {
  position: relative;
}

main #hero .hero-buttons-container .buttons button {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  width: 4rem;
  height: 4rem;
  outline: none;
  border: none;
  font-size: 0.5rem;
  font-weight: 100;
  border-radius: 50%;
  background: #fff;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border: 2px solid #fff;
  z-index: 100;
  cursor: pointer;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

main #hero .hero-buttons-container .buttons button:hover, main #hero .hero-buttons-container .buttons button:focus {
  opacity: 0.7;
  color: #fff;
}

main #hero .hero-buttons-container .buttons button img {
  width: 1.5rem;
  -o-object-fit: contain;
     object-fit: contain;
}

main #hero .hero-buttons-container .buttons [aria-label="Previous"] {
  -webkit-transform: translate(-200%, -50%);
          transform: translate(-200%, -50%);
}

main #hero .hero-buttons-container .buttons [aria-label="Previous"]:hover, main #hero .hero-buttons-container .buttons [aria-label="Previous"]:focus {
  -webkit-transform: translate(-200%, -50%) scale(0.95);
          transform: translate(-200%, -50%) scale(0.95);
}

main #hero .hero-buttons-container .buttons [aria-label="Next"] {
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
}

main #hero .hero-buttons-container .buttons [aria-label="Next"]:hover, main #hero .hero-buttons-container .buttons [aria-label="Next"]:focus {
  -webkit-transform: translate(100%, -50%) scale(0.95);
          transform: translate(100%, -50%) scale(0.95);
}

main #hero .hero-buttons-container .buttons::after {
  background: #fff;
  content: "";
  height: 1px;
  width: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 99;
}

main #hero .slick-arrow {
  display: none !important;
}

main #services {
  min-height: 100vh;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

main #services article {
  margin: 5rem auto;
}

main #peoples {
  padding: 5rem 0;
  background: #000;
}

main #peoples .img-container {
  position: relative;
}

main #peoples .img-container p {
  position: absolute;
  top: 6rem;
  left: 10%;
  z-index: 10;
  width: 40vmax;
  font-size: 3rem;
}

@media (max-width: 650px) {
  main #peoples .img-container img {
    -o-object-fit: cover;
       object-fit: cover;
  }
  main #peoples .img-container p {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
  }
  main #peoples .img-container p::after {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #fff;
    height: 0px;
    bottom: 0;
    top: -1rem;
    right: 0;
  }
}

#home {
  overflow-x: hidden;
}

#home .parallax-section .parallax-heading {
  position: relative;
  margin: 5%;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  letter-spacing: 0.2em;
  font-size: 2rem;
}

#home .parallax-section .parallax-heading::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50%;
  background-color: #fff;
  bottom: -0.5rem;
  right: -3rem;
}

#home .parallax-section .parallax-container .parallax-item {
  height: 450px;
  position: relative;
}

#home .parallax-section .parallax-container .parallax-item img {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

#home .parallax-section .parallax-container .parallax-item .txt-container {
  position: absolute;
  top: 10%;
  padding-inline: 5%;
}

#home .parallax-section .parallax-container .parallax-item .txt-container h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

#home .parallax-section .parallax-container .parallax-item .txt-container p {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

#home .parallax-section .parallax-container .parallax-item:nth-child(even) .txt-container {
  top: revert;
  right: 0;
  bottom: 10%;
  padding-inline: 5%;
  text-align: right;
}

#home .parallax-section .home-end-buttons {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template: 1fr/ 1fr 1fr;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
  max-width: 800px;
  margin: 5rem auto;
}

#home .parallax-section .home-end-buttons .btn {
  max-width: 100%;
  -ms-grid-column-align: center;
      justify-self: center;
  background-color: #fff;
  color: #000;
  border: none;
  outline: none;
}

#home .parallax-section .home-end-buttons .btn:hover, #home .parallax-section .home-end-buttons .btn:focus {
  color: #fff;
}

#home .parallax-section .home-end-buttons .btn:hover::before, #home .parallax-section .home-end-buttons .btn:focus::before {
  background-color: #000;
  border: none;
  outline: none;
}

@media (max-width: 650px) {
  #home .parallax-section .home-end-buttons {
    -ms-grid-rows: 1fr 1fr;
    -ms-grid-columns: 1fr;
        grid-template: 1fr 1fr / 1fr;
    gap: 1rem;
  }
}

#about {
  width: 100vw;
}

#about article .grid-container-txt-left,
#about article .grid-container-txt-right {
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
}

#about article .grid-container-txt-left .txt-container,
#about article .grid-container-txt-right .txt-container {
  max-width: 35ch;
}

#about article .grid-container-txt-left .txt-container p,
#about article .grid-container-txt-right .txt-container p {
  max-width: 40ch;
}

#about article .grid-container-txt-left > .img-container,
#about article .grid-container-txt-right > .img-container {
  max-width: 100%;
}

#about article .grid-container-txt-right {
  -ms-grid-columns: 1fr auto;
      grid-template-columns: 1fr auto;
}

#contact {
  width: 100vw;
}

#contact .hero-slider-grid-container .txt-container {
  z-index: 1;
  position: revert;
}

#contact .hero-slider-grid-container .hero-slider-container {
  z-index: 0;
}

#contact .hero-slider-grid-container .hero-slider-container .slick-arrow {
  display: none !important;
}

#contact .tabs .tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5rem 0;
  gap: 1rem;
}

#contact .tabs .tab button {
  padding: 1rem 3rem;
  background: no-repeat;
  outline: none;
  border: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 0.2rem;
  cursor: pointer;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  border-radius: 5px;
}

#contact .tabs .tab button:hover, #contact .tabs .tab button:focus {
  background: #fff;
  color: #000;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#contact .tabs form .form-item,
#contact .tabs form .checkboxes-container,
#contact .tabs form .form-item-submit {
  width: 40rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
}

#contact .tabs form .form-item input,
#contact .tabs form .form-item textarea,
#contact .tabs form .form-item button,
#contact .tabs form .checkboxes-container input,
#contact .tabs form .checkboxes-container textarea,
#contact .tabs form .checkboxes-container button,
#contact .tabs form .form-item-submit input,
#contact .tabs form .form-item-submit textarea,
#contact .tabs form .form-item-submit button {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
}

#contact .tabs form .form-item input::-webkit-input-placeholder,
#contact .tabs form .form-item textarea::-webkit-input-placeholder,
#contact .tabs form .form-item button::-webkit-input-placeholder,
#contact .tabs form .checkboxes-container input::-webkit-input-placeholder,
#contact .tabs form .checkboxes-container textarea::-webkit-input-placeholder,
#contact .tabs form .checkboxes-container button::-webkit-input-placeholder,
#contact .tabs form .form-item-submit input::-webkit-input-placeholder,
#contact .tabs form .form-item-submit textarea::-webkit-input-placeholder,
#contact .tabs form .form-item-submit button::-webkit-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input:-ms-input-placeholder,
#contact .tabs form .form-item textarea:-ms-input-placeholder,
#contact .tabs form .form-item button:-ms-input-placeholder,
#contact .tabs form .checkboxes-container input:-ms-input-placeholder,
#contact .tabs form .checkboxes-container textarea:-ms-input-placeholder,
#contact .tabs form .checkboxes-container button:-ms-input-placeholder,
#contact .tabs form .form-item-submit input:-ms-input-placeholder,
#contact .tabs form .form-item-submit textarea:-ms-input-placeholder,
#contact .tabs form .form-item-submit button:-ms-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input::-ms-input-placeholder,
#contact .tabs form .form-item textarea::-ms-input-placeholder,
#contact .tabs form .form-item button::-ms-input-placeholder,
#contact .tabs form .checkboxes-container input::-ms-input-placeholder,
#contact .tabs form .checkboxes-container textarea::-ms-input-placeholder,
#contact .tabs form .checkboxes-container button::-ms-input-placeholder,
#contact .tabs form .form-item-submit input::-ms-input-placeholder,
#contact .tabs form .form-item-submit textarea::-ms-input-placeholder,
#contact .tabs form .form-item-submit button::-ms-input-placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item input::placeholder,
#contact .tabs form .form-item textarea::placeholder,
#contact .tabs form .form-item button::placeholder,
#contact .tabs form .checkboxes-container input::placeholder,
#contact .tabs form .checkboxes-container textarea::placeholder,
#contact .tabs form .checkboxes-container button::placeholder,
#contact .tabs form .form-item-submit input::placeholder,
#contact .tabs form .form-item-submit textarea::placeholder,
#contact .tabs form .form-item-submit button::placeholder {
  color: rgba(222, 222, 222, 0.5);
}

#contact .tabs form .form-item textarea,
#contact .tabs form .checkboxes-container textarea,
#contact .tabs form .form-item-submit textarea {
  height: 1.5rem;
  max-width: 100%;
  resize: vertical;
}

#contact .tabs form .form-item button,
#contact .tabs form .checkboxes-container button,
#contact .tabs form .form-item-submit button {
  padding: 1rem 3rem;
  border: none;
  background: #fff;
  color: #000;
  cursor: pointer;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  border-radius: 5px;
}

#contact .tabs form .form-item button:hover, #contact .tabs form .form-item button:active, #contact .tabs form .form-item button:focus,
#contact .tabs form .checkboxes-container button:hover,
#contact .tabs form .checkboxes-container button:active,
#contact .tabs form .checkboxes-container button:focus,
#contact .tabs form .form-item-submit button:hover,
#contact .tabs form .form-item-submit button:active,
#contact .tabs form .form-item-submit button:focus {
  background: #000;
  color: #fff;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#contact .tabs form .form-item .check-box-item,
#contact .tabs form .checkboxes-container .check-box-item,
#contact .tabs form .form-item-submit .check-box-item {
  padding-bottom: 0.5rem;
}

#contact .tabs form .form-item .check-box-item label input,
#contact .tabs form .checkboxes-container .check-box-item label input,
#contact .tabs form .form-item-submit .check-box-item label input {
  margin-right: 0.5rem;
  width: revert;
}

#contact .tabs form .form-item .check-box-item:last-of-type,
#contact .tabs form .checkboxes-container .check-box-item:last-of-type,
#contact .tabs form .form-item-submit .check-box-item:last-of-type {
  padding-bottom: 0;
}

#contact .tabs form .form-item .message,
#contact .tabs form .checkboxes-container .message,
#contact .tabs form .form-item-submit .message {
  color: royalblue;
  font-size: 1rem;
  text-align: center;
}

#contact .tabs .start-project {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  height: auto;
}

#contact .tabs .join-team {
  letter-spacing: 0.3rem;
  text-align: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  height: auto;
}

#contact .tabs .join-team .rich-txt {
  max-width: 1000px;
  line-height: 1.3;
  position: relative;
}

#contact .tabs .join-team .rich-txt::after, #contact .tabs .join-team .rich-txt::before {
  content: "";
  margin: -2rem auto;
  display: block;
  background: #fff;
  height: 1px;
  width: 20vw;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#contact .tabs .join-team .rich-txt::before {
  top: 0;
  width: 30vw;
}

#contact .tabs .join-team .rich-txt::after {
  bottom: 0;
}

#contact .tabs .join-team .rich-txt p {
  margin-top: 1rem;
  line-height: 1.3;
}

#contact .tabs .join-team .intersted h2 {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  margin-bottom: 2rem;
}

#contact .tabs .join-team .intersted h2::after {
  bottom: 0;
  position: absolute;
  content: "";
  width: 70%;
  margin: -0.5rem auto;
  height: 1px;
  background: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#contact .tabs .join-team .jobs-container {
  letter-spacing: 0.3rem;
}

#contact .tabs .join-team .jobs-container h2 {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}

#contact .tabs .join-team .jobs-container h2::after {
  bottom: 0;
  position: absolute;
  content: "";
  width: 70%;
  margin: -0.5rem auto;
  height: 1px;
  background: #fff;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

#contact .tabs .join-team .jobs-container p {
  margin-top: 2rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item {
  margin: 1rem auto;
  max-width: 1000px;
  border-radius: 5px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: #fff;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem 2rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .txt-container {
  width: 17rem;
  color: #000;
  letter-spacing: 0.3rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .txt-container h3 {
  margin-bottom: 0.2rem;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .txt-container p {
  margin: 0;
  color: #ff0707;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .link-container a {
  color: #fff;
  background: #ff0707;
  border: #ff0707 1px solid;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  display: inline-block;
}

#contact .tabs .join-team .jobs-container .jobs .job-item .link-container a:hover, #contact .tabs .join-team .jobs-container .jobs .job-item .link-container a:focus {
  background: #fff;
  color: #ff0707;
}

#contact .tabs .join-team > * {
  margin: 5rem auto;
}

#contact .contact-info-container {
  padding: 2rem 5%;
  padding-bottom: 3rem;
}

#contact .contact-info-container .info-card {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  color: #000;
  letter-spacing: 0.2em;
}

#contact .contact-info-container .info-card .grid-container {
  all: revert;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: auto auto;
      grid-template: 1fr / auto auto;
  gap: 1rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

#contact .contact-info-container .info-card .grid-container .social-links-area {
  display: -ms-grid;
  display: grid;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  gap: 1rem;
}

#contact .contact-info-container .info-card .grid-container .social-links-area > * {
  margin-left: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

#contact .contact-info-container .info-card .grid-container .icon-container img {
  max-width: 2.5rem;
}

@media (max-width: 650px) {
  #contact .contact-info-container .info-card .grid-container {
    -ms-grid-rows: auto auto;
    -ms-grid-columns: auto;
        grid-template: auto auto / auto;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
  }
  #contact .contact-info-container .info-card .grid-container .social-links-area > * {
    margin-left: revert;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

@media (max-width: 650px) {
  #contact .tabs {
    min-height: initial;
    height: auto;
  }
  #contact .tabs .tab {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #contact .tabs form {
    width: 100vw;
  }
  #contact .tabs form .form-item,
  #contact .tabs form .form-item-submit,
  #contact .tabs form .checkboxes-container {
    width: 80%;
  }
  #contact .tabs .join-team .rich-txt,
  #contact .tabs .join-team .jobs-container {
    padding: 0 5%;
  }
}

#work .tags-buttons-container {
  margin: 5rem 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

#work .tags-buttons-container .tag-item .btn {
  letter-spacing: 0.2em;
  max-width: 100%;
  text-transform: capitalize;
  padding: 0.5em 1.5em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: block;
  color: #de2e2e;
  background: #fff;
  border: none;
}

#work .tags-buttons-container .tag-item .btn:hover, #work .tags-buttons-container .tag-item .btn:focus {
  color: inherit;
  background: inherit;
  color: #de2e2e;
  background: #fff;
}

#work .tags-buttons-container .tag-item .btn:hover::before, #work .tags-buttons-container .tag-item .btn:focus::before {
  width: 0%;
  display: none;
  background: #ff0707;
}

#work .tags-buttons-container .tag-item .btn.active {
  color: #fff;
  -webkit-transform: revert;
          transform: revert;
  background: #ff0707;
}

#work .tags-buttons-container .tag-item .btn.active::before {
  display: none;
  width: 100%;
  background: #ff0707;
}

#work .works-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  -ms-grid-columns: (minmax(22rem, 1fr))[auto-fill];
      grid-template: auto/repeat(auto-fill, minmax(22rem, 1fr));
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4rem;
  max-width: 1600px;
  margin: 0 auto;
  padding: 5%;
}

#work .works-container .work-item {
  letter-spacing: 0.2em;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}

#work .works-container .work-item .title {
  font-weight: bold;
  margin-top: 2rem;
  font-size: 1.2rem;
}

#work .works-container .work-item .tags {
  font-weight: lighter;
  margin-top: 0.8rem;
  text-transform: capitalize;
}

#selected-work-page {
  background-color: #1b1b1b;
  font-family: Montserrat;
  line-height: 1.4;
  font-size: 1.1rem;
}

#selected-work-page .two-column-grid {
  display: -ms-grid;
  display: grid;
  gap: 0rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template: 1fr/ 1fr 1fr;
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

#selected-work-page .two-column-grid .txt-container {
  max-width: 30ch;
  -ms-grid-column-align: center;
      justify-self: center;
  font-weight: 500;
}

@media (max-width: 650px) {
  #selected-work-page .two-column-grid {
    -ms-grid-rows: auto auto;
    -ms-grid-columns: 1fr;
        grid-template: auto auto/ 1fr;
    gap: 1rem;
  }
  #selected-work-page .two-column-grid .txt-container {
    max-width: 80%;
    text-align: center;
  }
  #selected-work-page .two-column-grid .txt-container::after {
    position: relative;
    display: block;
    content: "";
    background-color: #fff;
    max-width: 15rem;
    width: 100vw;
    height: 2px;
    left: 50%;
    -webkit-transform: translate(-50%, 1rem);
            transform: translate(-50%, 1rem);
  }
}

#selected-work-page .dispay-img-txt-group p {
  padding: 4rem 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

#selected-work-page .dispay-img-txt-group p::after {
  position: relative;
  display: block;
  content: "";
  background-color: #fff;
  max-width: 15rem;
  width: 100vw;
  height: 2px;
  left: 50%;
  -webkit-transform: translate(-50%, 1rem);
          transform: translate(-50%, 1rem);
  margin-bottom: 1rem;
}

#selected-work-page .display-img-grid {
  display: -ms-grid;
  display: grid;
  padding: 1rem calc(min(5rem, 15%));
  padding-top: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
      grid-template: 1fr/ 1fr 1fr;
}

@media (max-width: 650px) {
  #selected-work-page .display-img-grid {
    -ms-grid-rows: auto auto;
    -ms-grid-columns: 1fr;
        grid-template: auto auto/ 1fr;
  }
}

footer {
  background-color: #fff;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  color: #000;
  padding: 5rem 0;
  letter-spacing: 0.3rem;
}

footer .email {
  margin: 0 10rem;
  color: #ff0707;
  font-size: 2rem;
}

footer .email::after {
  display: inline-block;
  content: "";
  background: #000;
  height: 1px;
  width: 11rem;
  position: relative;
  right: 7rem;
  top: 1rem;
}

footer .info-container {
  margin: 3rem 10rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 50rem;
}

footer .info-container .address-container {
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
}

footer .info-container .address-container h3 {
  margin-bottom: 1rem;
  font-weight: 500;
}

footer .info-container .address-container address {
  letter-spacing: 0.2rem;
  font-style: normal;
  font-weight: normal;
}

footer .info-container .links-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
}

footer .info-container .links-container .link-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

footer .info-container .links-container .link-set a {
  color: #000;
  margin-bottom: 1rem;
  font-weight: 500;
}

@media (max-width: 650px) {
  footer .email {
    margin: 0 auto;
    text-align: center;
    font-size: 1.5rem;
  }
  footer .email::after {
    right: 0;
    top: 0;
  }
  footer .info-container {
    margin: 3rem 1rem;
    -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
  }
  footer .info-container .links-container {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  footer .info-container .links-container .link-set a {
    margin-bottom: 0.5rem;
  }
}

footer .social-links-container {
  padding-top: 1rem;
}

.gmaps-container {
  text-decoration: none;
  overflow: hidden;
  max-width: 100%;
  width: 100vw;
  position: relative;
}

@media (max-width: 650px) {
  #services > article {
    overflow-x: hidden;
  }
}
